export const LOGIN = "/login";
export const LOGIN_ADMIN = "/login-admin";
export const LOGOUT = "/logout";
export const LEGACY_LOGIN = "/account/login";
export const LEGACY_LOGOUT = "/account/logout";
export const RESET_PASSWORD = "/password/reset";

export const linkTo = {
  login: () => LOGIN,
  loginAdmin: (search = "") => `${LOGIN}${search}`,
  logout: () => LOGOUT,
};
